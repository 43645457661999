.mob-view-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    // padding: 80px 0 160px;
    flex-direction: column;
    align-items: center;
    font-size: 18px;

    .view-box-top {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 80px;

        .top-logo {
            width: 100px;
            height: 100px;

            img {
                width: 100%;
                border-radius: 50%;
                object-fit: contain;
            }
        }

        .link-desc {
            font-size: 14px;
            width: 80vw;
            margin-top: 20px;
            text-align: center;
        }
    }


    .view-box-bottom {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 80px;

        .wx-btn-box {
            margin-bottom: 30px;
            width: 32vw;
            min-width: 110px;
            padding: 8px;
            border-radius: 8px;
            box-sizing: border-box;
            background-color: green;
            display: flex;
            justify-content: center;
            align-items: center;

            .wx-btn {
                text-align: center;
                color: #fff;
            }
        }

        .waiting-message {
            text-align: center;
            font-size: 22px;
            font-weight: 500;
            margin-bottom: 20px;
        }

        .link-box {
            font-size: 14px;

            .link-btn {
                cursor: pointer;
                color: #1677ff;
                // text-decoration: underline;
            }
        }
    }




}