// 重置标签样式
body,
div,
dl,
dt,
dd,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
pre,
code,
form,
fieldset,
legend,
input,
textarea,
p,
blockquote,
th,
td {
    margin: 0;
    padding: 0;
    color: inherit;
}

html {
    width: 100%;
    min-height: 100vh;
}

body {
    font-style: normal;
    // font-weight: 300;
    font-family: "FZLanTingHeiS-R-GB", 'Zona Pro', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 100vh;
    background-color: #fff;
    position: relative;
}

ul,
ol,
li {
    list-style: none;
}

a {
    text-decoration: none;
    color: inherit;

    &.txt-under {
        text-decoration: underline;
    }
}

img {
    display: block;
}


// @media screen and (min-width: 1024px) {
//     html {
//         // 移动端ui宽度375、pc端1920、rootValue=3.75*19.2(如果设计稿不是这个值，要在/craco.config.js修改rootValue)
//         // ui 宽度 1920px
//         // 1vw = 19.2px
//         // rootValue = 3.75 * 19.2
//         // font-size = 3.75vw = 19.2 * 3.75 px
//         // write = font-size / rootValue = 1px
//         // 在ui宽度下为 72px
//         font-size: 72px
//     }
// }


@media screen and (max-width: 1023px) {
    html {
        // ui 宽度 375px
        // 1vw = 3.75px
        // rootValue = 3.75 * 19.2
        // font-size = 19.2vw = 19.2 * 3.75 px
        // write = font-size / rootValue = 1px
        // 在ui宽度下为 72px
        font-size: 3.75vw
    }
}



// 滚动条样式
// ::-webkit-scrollbar {
//   width: 5px;
//   height: 5px;
//   background-color: transparent;
// }
// ::-webkit-scrollbar-track {
//   border-radius: 5px;
// }
// ::-webkit-scrollbar-thumb {
//   background-color: rgba(35, 24, 21, 0.6);
//   transition: all .2s;
//   border-radius: 5px;
// }
// ::-webkit-scrollbar-thumb:hover {
//   background-color: rgba(35, 24, 21, 0.9);
// }