.pc-view-box {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
    font-size: 16px;
    box-sizing: border-box;
    padding-bottom: 20px;

    .box-top {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .top-logo {
            width: 80px;
            height: 80px;


            img {
                width: 100%;
                border-radius: 50%;
                object-fit: contain;
            }
        }

        .link-desc {
            max-width: 30%;
            word-break: break-all;
            margin-top: 20px;

            text-align: center;
        }
    }

    .box-bottom {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .solar-code {
            width: 200px;


            img {
                width: 100%;
                object-fit: contain;
            }
        }

        .solar-code-tip {
            font-size: 14px;
            text-align: center;
            margin-top: 20px;
        }

        .waiting-message {
            font-size: 18px;
            font-weight: 500;
            margin: 40px 0;
        }

        .link-box {
            font-size: 16px;

            .link-btn {
                cursor: pointer;
                color: blue;
                text-decoration: underline;
            }
        }
    }


}